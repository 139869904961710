import commonSettings from "@common/settings/bastide-diabete";
import administrationMenu from "./menus/administration.bastide-diabete";
import administrationFooter from "./footer/administration.bastide-diabete";
import administrationDashboard from "./dashboard/administration.bastide-diabete";
import supportedLocales from "./supported-locales/supported-locales.bastide-diabete";

const sentryDsn = "";

const titleApp = "Bastide Diabète";
const name = "Bastide";
const longName = "Bastide Médical";
const extranetContactNumber = "";
const extranetAddresseeName = "Agence Bastide";

const headOffice =
  "Centre d'activité Euro 2000 - 12, avenue de la Dame - 30132 Caissargues <br /> S.A. au capital de 3 303 284, 40 € - R.C. Nîmes B 305 635 039 - SIRET n°305 635 039 00194 - N° TVA Intracommunautaire : FR 19 305 635 039";

export default {
  ...commonSettings,
  administrationFooter,
  administrationMenu,
  extranetContactNumber,
  sentryDsn,
  titleApp,
  name,
  longName,
  headOffice,
  administrationDashboard,
  supportedLocales,
  extranetAddresseeName
};
