module.exports = {
  externalService: "sap",
  customIdGeneration: "objectId",
  defaultDivisionId: "D",
  handledDivisionIds: ["D"],
  overridedModules: [
    {
      key: "IndexDashboardAdmin",
      value: "IndexDashboardAdminDiabete"
    },
    {
      key: "PatientView",
      value: "PatientViewDiabete"
    },
    {
      key: "ContractsAdd",
      value: "ContractsAddDiabete"
    },
    {
      key: "PatientViewInformations",
      value: "PatientViewInformationsDiabete"
    },

    // PRESCRIBERS
    { key: "IndexPrescriberView", value: "IndexPrescriberViewDiabete" },
    { key: "PrescriberEditProtocols", value: "PrescriberEditProtocolsDiabete" },
    { key: "PrescriberViewProtocols", value: "PrescriberViewProtocolsDiabete" },
    { key: "TabPrescriberViewGlobalInformations", value: "TabPrescriberViewGlobalInformationsDiabete" },
    { key: "PrescriberEditGlobalInformations", value: "PrescriberEditGlobalInformationsDiabete" },
    { key: "IndexPagePrescriberAddForm", value: "IndexPagePrescriberAddFormDiabete" }
  ],
  relationPatientIntervenantMode: "employeeId"
};
