const { SECRETAIRE_FACTURATION } = require("@common/constants/roles");

export default [
  {
    id: "achievedInterventions",
    description: "Interventions with achieved status",
    active: true,
    roles: [SECRETAIRE_FACTURATION]
  },
  {
    id: "desappareillageInterventions",
    description: "Intenvetions with unpair type",
    active: true,
    roles: [SECRETAIRE_FACTURATION]
  },
  {
    id: "conversationsWithAttachment",
    description: "Conversations with attachment",
    active: true,
    roles: [SECRETAIRE_FACTURATION]
  }
];
