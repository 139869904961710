/* ========= PRESCRIBER ========= */
import getDefaultDivision from "@ui/helpers/_functions/getDefaultDivision";
import { componentNameResolver } from "@common/helpers/settings/overrideResolver";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "router.admin";

const settings = require(`@/settings/${__PLATFORM__}`);

// Prescriber Add Segments
const PrescriberAddSearch = () => import(/* webpackChunkName: "prescriber" */ "@/modules/prescriber/add/_components/PrescriberAddSearch");

const PrescriberViewSignatureDap = () =>
  import(/* webpackChunkName: "prescriber" */ "@/modules/prescriber/view/_components/PrescriberViewSignatureDap");
const PrescriberViewMedicalDevices = () =>
  import(/* webpackChunkName: "prescriber" */ "@/modules/prescriber/view/_components/PrescriberViewMedicalDevices");

// Prescriber Edit Segments
const PrescriberEditSignatureDap = () =>
  import(/* webpackChunkName: "prescriber" */ "@/modules/prescriber/edit/_components/PrescriberEditSignatureDap");
const PrescriberEditProtocols = () => import(/* webpackChunkName: "prescriber" */ "@/modules/prescriber/edit/_components/PrescriberEditProtocols");

/* ========= PRESCRIBER GROUP ========= */

// Prescribers Group Add
const PrescribersGroupSearch = () =>
  import(/* webpackChunkName: "prescriber" */ "@/modules/prescribers-group/add/_components/PrescribersGroupSearch");
const PrescribersGroupAddForm = () => import(/* webpackChunkName: "prescriber" */ "@/modules/prescribers-group/add/_components/PrescribersGroupAdd");

// Prescribers Group View
const PrescribersGroupViewGlobalInformations = () =>
  import(/* webpackChunkName: "prescriber" */ "@ui/pages/prescribers-group/view/_components/GlobalInformations");

// Prescribers Group Edit
const PrescribersGroupEdit = () => import(/* webpackChunkName: "prescriber" */ "@/modules/prescribers-group/edit");

const PrescriberAdd = () => import(/* webpackChunkName: "prescriber" */ "@/modules/prescriber/add");

const PrescribersGroups = () => import(/* webpackChunkName: "prescriber" */ "@/modules/prescribers-groups");
const PrescribersGroupAdd = () => import(/* webpackChunkName: "prescriber" */ "@/modules/prescribers-group/add");
const PrescribersGroupView = () => import(/* webpackChunkName: "prescriber" */ "@/modules/prescribers-group/view");

const {
  prescribersRights: { CREATE_PRESCRIBER, ACCESS_PRESCRIBER, EDIT_PRESCRIBER }
} = require("@common/services/acm/rights");

export default [
  {
    path: "prescribers",
    alias: "prescripteurs",
    name: "prescribers",
    redirect: () => `prescribers/${getDefaultDivision()}`
  },
  {
    path: "prescribers/:division",
    alias: "prescripteurs/:division",
    name: "prescribersList",
    component: () => {
      const component = componentNameResolver("IndexPagePrescribersList", settings.default.overridedModules);
      return import(`@/modules/prescribers/${component}`);
    },
    props: true,
    meta: {
      title: localize(localPath, "prescribers.title--prescribersList"),
      rights: [ACCESS_PRESCRIBER]
    }
  },
  {
    path: "prescriber/add",
    alias: "prescripteur/creation",
    component: PrescriberAdd,
    children: [
      {
        path: "",
        name: "prescriberAdd",
        component: () => {
          const component = componentNameResolver("IndexPagePrescriberAddForm", settings.default.overridedModules);
          return import(`@/modules/prescriber/add/_components/${component}`);
        },
        meta: {
          title: localize(localPath, "prescribers.title--prescriberAdd"),
          rights: [CREATE_PRESCRIBER]
        },
        beforeEnter: (to, from, next) => {
          if (from.name !== "prescriberAddSearch") {
            next({ name: "prescriberAddSearch" });
          } else {
            next();
          }
        }
      },
      {
        path: "search",
        alias: "recherche",
        name: "prescriberAddSearch",
        component: PrescriberAddSearch,
        meta: {
          title: localize(localPath, "prescribers.title--prescriberAddSearch"),
          rights: [CREATE_PRESCRIBER]
        }
      }
    ]
  },
  {
    path: "prescriber/:id",
    alias: "prescripteur/:id",
    component: () => {
      const component = componentNameResolver("IndexPrescriberView", settings.default.overridedModules);
      return import(`@/modules/prescriber/view/${component}`);
    },

    children: [
      {
        path: "",
        name: "IndexPrescriberView",
        meta: {
          title: localize(localPath, "prescribers.title--prescriberView"),
          rights: [ACCESS_PRESCRIBER]
        },
        component: () => {
          const component = componentNameResolver("TabPrescriberViewInternalInformations", settings.default.overridedModules);
          return import(`@/modules/prescriber/view/_components/${component}`);
        }
      },
      {
        path: "internal-info/edit",
        alias: "informations-internes/edition",
        name: "prescriberEditInternalInformations",
        meta: {
          title: localize(localPath, "prescribers.title--prescriberView"),
          rights: [EDIT_PRESCRIBER]
        },
        component: () => {
          const component = componentNameResolver("PrescriberEditInternalInformations", settings.default.overridedModules);
          return import(`@/modules/prescriber/edit/_components/internal-informations/${component}`);
        }
      },
      {
        path: "info",
        alias: "informations",
        name: "prescriberViewGlobalInformations",
        meta: {
          title: localize(localPath, "prescribers.title--prescriberView"),
          rights: [ACCESS_PRESCRIBER]
        },
        component: () => {
          const component = componentNameResolver("TabPrescriberViewGlobalInformations", settings.default.overridedModules);
          return import(`@/modules/prescriber/view/_components/${component}`);
        }
      },
      {
        path: "info/edit",
        alias: "informations/edition",
        name: "prescriberEditGlobalInformations",
        meta: {
          title: localize(localPath, "prescribers.title--prescriberView"),
          rights: [EDIT_PRESCRIBER]
        },
        component: () => {
          const component = componentNameResolver("PrescriberEditGlobalInformations", settings.default.overridedModules);
          return import(`@/modules/prescriber/edit/_components/global-informations/${component}`);
        }
      },
      {
        path: "protocols",
        alias: "protocoles",
        name: "prescriberViewProtocols",
        meta: {
          title: localize(localPath, "prescribers.title--prescriberView"),
          rights: [ACCESS_PRESCRIBER]
        },
        component: () => {
          const component = componentNameResolver("PrescriberViewProtocols", settings.default.overridedModules);
          return import(`@/modules/prescriber/view/_components/${component}`);
        }
      },
      {
        path: "protocols/edit",
        alias: "protocoles/edition",
        name: "prescriberEditProtocols",
        meta: {
          title: localize(localPath, "prescribers.title--prescriberView"),
          rights: [EDIT_PRESCRIBER]
        },
        component: () => {
          const component = componentNameResolver("PrescriberEditProtocols", settings.default.overridedModules);
          return import(`@/modules/prescriber/edit/_components/${component}`);
        }
      },
      {
        path: "signature-dap",
        name: "prescriberViewSignatureDap",
        meta: {
          title: localize(localPath, "prescribers.title--prescriberView"),
          rights: [ACCESS_PRESCRIBER]
        },
        component: PrescriberViewSignatureDap
      },
      {
        path: "signature-dap/edit",
        alias: "signature-dap/edition",
        name: "prescriberEditSignatureDap",
        meta: {
          title: localize(localPath, "prescribers.title--prescriberView"),
          rights: [EDIT_PRESCRIBER]
        },
        component: PrescriberEditSignatureDap
      },
      {
        path: "medical-devices",
        alias: "materiel-medical",
        name: "prescriberViewMedicalDevices",
        meta: {
          title: localize(localPath, "prescribers.title--prescriberView"),
          rights: [ACCESS_PRESCRIBER]
        },
        component: PrescriberViewMedicalDevices
      }
    ]
  },
  {
    path: "prescribers-groups",
    alias: "regroupements-prescripteurs",
    name: "prescribersGroups",
    redirect: () => `prescribers-groups/${getDefaultDivision()}`
  },
  {
    path: "prescribers-groups/:division",
    alias: "regroupements-prescripteurs/:division",
    name: "prescribersGroupsList",
    component: PrescribersGroups,
    props: true,
    meta: {
      title: localize(localPath, "prescribers-groups.title--prescribersGroupsList"),
      rights: [ACCESS_PRESCRIBER]
    }
  },
  {
    path: "prescribers-group/add",
    alias: "regroupement-prescripteurs/creation",
    component: PrescribersGroupAdd,
    children: [
      {
        path: "",
        name: "prescribersGroupAdd",
        component: PrescribersGroupAddForm,
        meta: {
          title: localize(localPath, "prescribers-groups.title--prescribersGroupAdd"),
          rights: [CREATE_PRESCRIBER]
        },
        beforeEnter: (to, from, next) => {
          if (from.name !== "prescribersGroupAddSearch") {
            next({ name: "prescribersGroupAddSearch" });
          } else {
            next();
          }
        }
      },
      {
        path: "search",
        name: "prescribersGroupAddSearch",
        component: PrescribersGroupSearch,
        meta: {
          title: localize(localPath, "prescribers-groups.title--prescribersGroupAddSearch"),
          rights: [CREATE_PRESCRIBER]
        }
      }
    ]
  },
  {
    path: "prescribers-group/:id",
    alias: "regroupement-prescripteurs/:id",
    component: PrescribersGroupView,
    children: [
      {
        path: "",
        name: "prescribersGroupView",
        meta: {
          title: localize(localPath, "prescribers-groups.title--prescribersGroupView"),
          rights: [ACCESS_PRESCRIBER]
        },
        component: PrescribersGroupViewGlobalInformations
      },
      {
        path: "edit",
        alias: "edition",
        name: "prescribersGroupEdit",
        meta: {
          title: localize(localPath, "prescribers-groups.title--prescribersGroupView"),
          rights: [EDIT_PRESCRIBER]
        },
        component: PrescribersGroupEdit
      }
    ]
  }
];
