import LogoAppSmall from "@ui/components/icons/LogoAppSmall";
import * as icons from "@ui/components/icons";
import i18n from "@/i18n";

const {
  patientsRights: { CREATE_PATIENT, ACCESS_PATIENT },
  prescribersRights: { CREATE_PRESCRIBER, ACCESS_PRESCRIBER },
  planManagementRights: { ACCESS_PLAN_MANAGEMENT },
  interventionsRights: { ACCESS_INTERVENTION },
  toursRights: { ACCESS_TOUR },
  contractsRights: { CREATE_CONTRACT },
  productsRights: { ACCESS_PRODUCT },
  usersRights: { CREATE_USER, GET_USER, VIEW_USER },

  menuRights: {
    VIEW_MENU_MON_TRAVAIL,
    VIEW_MENU_INTERVENTIONS_TOURNEES,
    VIEW_MENU_ANOMALY_INTERVENTIONS,
    VIEW_MENU_TO_BE_PREPARED_INTERVENTIONS,
    VIEW_MENU_SEARCH_PATIENT,
    VIEW_MENU_STOCK_POLE
  },
  documentsRights: { VIEW_DOCUMENTS },
  supportTicketsRights: { ACCESS_SUPPORT_TICKETS },
  contactsRights: { CREATE_CONTACT, ACCESS_CONTACTS }
} = require("@common/services/acm/rights");

/**
 * When using dynamic redirect, we must use the route's path instead of the route's name.
 * Example : `routerLink: { path: "/foo" }` instead of `routerLink: { name: "foo" }`.
 */
const menu = [
  {
    title: i18n.t("menus.administration.link--dashboard"),
    slug: "dashboard",
    icon: LogoAppSmall,
    routerLink: { name: "dashboard" },
    disabled: false,
    isHome: true
  },
  {
    title: i18n.t("menus.administration.patients-medecins.title"),
    slug: "patients-medecins",
    icon: icons.iconUserClassicOutlined,
    disabled: false,
    items: [
      {
        title: i18n.t("menus.administration.patients-medecins.patients.title"),
        slug: "patients",
        links: [
          {
            icon: icons.iconSearch,
            title: i18n.t("menus.administration.patients-medecins.patients.link--patientSearch"),
            routerLink: {
              name: "patientSearch"
            },
            rights: [ACCESS_PATIENT, VIEW_MENU_SEARCH_PATIENT]
          },
          {
            icon: icons.iconAddPatientOutlined,
            title: i18n.t("menus.administration.patients-medecins.patients.link--patientAddSearch"),
            routerLink: {
              name: "patientAddSearch"
            },
            rights: [CREATE_PATIENT]
          },
          {
            icon: icons.iconPatientOutlined,
            title: i18n.t("menus.administration.patients-medecins.patients.link--patientsList"),
            routerLink: {
              name: "patients",
              alias: "patientsList"
            },
            rights: [ACCESS_PATIENT]
          }
        ]
      },
      {
        title: i18n.t("menus.administration.patients-medecins.medecins.title"),
        slug: "medecins",
        links: [
          {
            icon: icons.iconAddPrescriberOutlined,
            title: i18n.t("menus.administration.patients-medecins.medecins.link--prescriberAddSearch"),
            routerLink: {
              name: "prescriberAddSearch"
            },
            rights: [CREATE_PRESCRIBER]
          },
          {
            icon: icons.iconUserDoctorOutlined,
            title: i18n.t("menus.administration.patients-medecins.medecins.link--prescribersList"),
            routerLink: {
              name: "prescribers",
              alias: "prescribersList"
            },
            rights: [ACCESS_PRESCRIBER]
          },
          {
            icon: icons.iconAddPrescribersGroupOutlined,
            title: i18n.t("menus.administration.patients-medecins.medecins.link--prescribersGroupAddSearch"),
            routerLink: {
              name: "prescribersGroupAddSearch"
            },
            rights: [CREATE_PRESCRIBER]
          },
          {
            icon: icons.iconPrescribersGroup,
            title: i18n.t("menus.administration.patients-medecins.medecins.link--prescribersGroups"),
            routerLink: {
              name: "prescribersGroups",
              alias: "prescribersGroupsList"
            },
            rights: [ACCESS_PRESCRIBER]
          }
        ]
      },
      {
        title: i18n.t("menus.administration.services.contacts.title"),
        slug: "contacts",
        links: [
          {
            icon: icons.iconAddContact,
            title: i18n.t("menus.administration.services.contacts.link--contactsAdd"),
            routerLink: {
              name: "contactsAdd"
            },
            rights: [CREATE_CONTACT]
          },
          {
            icon: icons.iconContactsList,
            title: i18n.t("menus.administration.services.contacts.link--contactsList"),
            routerLink: {
              name: "contactsList"
            },
            rights: [ACCESS_CONTACTS]
          }
        ]
      }
    ]
  },
  {
    title: i18n.t("menus.administration.interventions-tours.title"),
    slug: "interventions-tournees",
    icon: icons.iconInterventionOutlined,
    disabled: false,
    items: [
      {
        title: i18n.t("menus.administration.interventions-tours.interventions.title"),
        slug: "interventions",
        rights: [VIEW_MENU_INTERVENTIONS_TOURNEES],
        links: [
          {
            icon: icons.iconCalendarMonth,
            title: i18n.t("menus.administration.interventions-tours.interventions.link--interventionsPlanning"),
            routerLink: {
              name: "interventionsPlanning"
            },
            rights: [ACCESS_PLAN_MANAGEMENT]
          },
          {
            icon: icons.iconList,
            title: i18n.t("menus.administration.interventions-tours.interventions.link--interventionsList"),
            routerLink: {
              name: "interventions"
            },
            rights: [ACCESS_INTERVENTION]
          },
          {
            icon: icons.iconList,
            title: i18n.t("menus.administration.interventions-tours.interventions.link--interventionsToBePrepared"),
            routerLink: {
              name: "interventionsToBePreparedStatus"
            },
            rights: [ACCESS_INTERVENTION, VIEW_MENU_TO_BE_PREPARED_INTERVENTIONS]
          },
          {
            icon: icons.iconError,
            title: i18n.t("menus.administration.interventions-tours.interventions.link--interventionsWithAnomaly"),
            routerLink: {
              name: "interventionsWithAnomaly"
            },
            rights: [ACCESS_INTERVENTION, VIEW_MENU_ANOMALY_INTERVENTIONS]
          }
        ]
      },
      {
        title: i18n.t("menus.administration.interventions-tours.tours.title"),
        slug: "tournees",
        rights: [VIEW_MENU_INTERVENTIONS_TOURNEES],
        links: [
          {
            icon: icons.iconInterventionOutlined,
            title: i18n.t("menus.administration.interventions-tours.tours.link--tours"),
            routerLink: {
              name: "interventionsTour"
            },
            rights: [ACCESS_TOUR]
          }
        ]
      },
      {
        title: i18n.t("menus.administration.interventions-tours.work.title"),
        slug: "mon-travail",
        rights: [VIEW_MENU_MON_TRAVAIL],
        links: [
          {
            icon: icons.iconInterventionOutlined,
            title: i18n.t("menus.administration.interventions-tours.work.link--productsStock"),
            rights: [ACCESS_PRODUCT],
            routerLink: {
              name: "productsStock"
            }
          },
          {
            icon: icons.iconInterventionOutlined,
            title: i18n.t("menus.administration.interventions-tours.work.link--interTour"),
            rights: [ACCESS_INTERVENTION],
            routerLink: {
              name: "interventionsSingleTour"
            }
          },
          {
            icon: icons.iconCalendarMonth,
            title: i18n.t("menus.administration.interventions-tours.work.link--interCalendar"),
            rights: [ACCESS_INTERVENTION],
            routerLink: {
              name: "interventionsCalendar"
            }
          },
          {
            icon: icons.iconList,
            title: i18n.t("menus.administration.interventions-tours.work.link--interWaiting"),
            rights: [ACCESS_INTERVENTION],
            routerLink: {
              name: "interventionsWaitingStatus"
            }
          },
          {
            icon: icons.iconList,
            title: i18n.t("menus.administration.interventions-tours.work.link--interReady"),
            rights: [ACCESS_INTERVENTION],
            routerLink: {
              name: "interventionsReadyStatus"
            }
          }
        ]
      }
    ]
  },
  {
    title: i18n.t("menus.administration.services.title"),
    slug: "prestation-ordonnances",
    icon: icons.iconPrescription,
    disabled: false,
    items: [
      {
        title: i18n.t("menus.administration.services.contracts.title"),
        slug: "prestations",
        links: [
          {
            icon: icons.iconMedical,
            title: i18n.t("menus.administration.services.contracts.link--createService"),
            routerLink: {
              name: "contractsAddPatient"
            },
            rights: [CREATE_CONTRACT]
          }
        ]
      },
      {
        title: i18n.t("menus.administration.services.documents.title"),
        slug: "documents",
        links: [
          {
            icon: icons.iconPrescription,
            title: i18n.t("menus.administration.services.documents.link--documentList"),
            routerLink: {
              name: "documentsList"
            },
            rights: [VIEW_DOCUMENTS]
          }
        ]
      }
    ]
  },
  {
    title: i18n.t("menus.administration.products.title"),
    slug: "products",
    icon: icons.iconMedicalDevice,
    disabled: false,
    items: [
      {
        title: i18n.t("menus.administration.products.products.title"),
        slug: "all-products",
        links: [
          {
            icon: icons.iconMedicalDevice,
            title: i18n.t("menus.administration.products.products.link--productsList"),
            routerLink: {
              name: "products",
              alias: "productsList"
            },
            rights: [ACCESS_PRODUCT]
          },
          {
            icon: icons.iconMedicalDevice,
            title: i18n.t("menus.administration.products.stock.link--productsStockPole"),
            rights: [VIEW_MENU_STOCK_POLE],
            routerLink: {
              name: "productsStockPole"
            }
          }
        ]
      }
    ]
  },
  {
    title: i18n.t("menus.administration.settings.title"),
    slug: "settings",
    icon: icons.iconSettings,
    notificationName: "countUnreadSupportTickets",
    disabled: false,
    rights: [VIEW_USER],
    items: [
      {
        title: i18n.t("menus.administration.settings.users.title"),
        slug: "utilisateurs",
        links: [
          {
            icon: icons.iconAddUserClassicOutlined,
            title: i18n.t("menus.administration.settings.users.link--usersSearch"),
            routerLink: {
              name: "usersSearch",
              alias: "usersSearch"
            },
            rights: [CREATE_USER]
          },
          {
            icon: icons.iconUserClassicOutlined,
            title: i18n.t("menus.administration.settings.users.link--usersList"),
            routerLink: {
              path: "/utilisateurs",
              alias: "usersList"
            },
            rights: [GET_USER]
          }
        ]
      }
    ]
  }
];

if (__APP_ENV__ === "production") {
  const entry = menu.find(item => item.slug === "settings");
  entry.items.push({
    title: i18n.t("menus.administration.settings.tickets.title"),
    slug: "tickets",
    links: [
      {
        icon: icons.iconTags,
        notificationName: "countUnreadSupportTickets",
        slug: "tickets",
        title: i18n.t("menus.administration.settings.tickets.link--ticketsList"),
        routerLink: {
          name: "supportTicketsList"
        },
        rights: [ACCESS_SUPPORT_TICKETS]
      }
    ]
  });
}

export default menu;
